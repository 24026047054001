import { ReactElement, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { Done, Circle, CropDin } from '@mui/icons-material';

import { courses as types } from '@codesass/types';

import useParallaxBackground from 'modules/courses/hooks/useParallaxBackground';

import { getDataImage } from 'modules/courses/helpers/images';

import { bg } from './shared/styles';
import Header from './shared/Header';

type BulletListProps = Omit<types.BulletListDesc, 'type'>;

type BulletStyleProps = { color: string } & (
  | {
      style: Exclude<
        NonNullable<Required<BulletListProps>['itemConfig']['style']>,
        'number'
      >;
    }
  | {
      style: 'number';
      order: number;
    }
);

const BulletStyle = (props: BulletStyleProps) => {
  const color = props.color;

  switch (props.style) {
    case 'circle':
      return <Circle sx={{ color: color }} />;
    case 'rectangle':
      return <CropDin sx={{ color: color }} />;
    case 'tick':
      return <Done sx={{ color: color }} />;
    case 'number':
      return (
        <Typography variant="h5" color={color}>
          {props.order}
        </Typography>
      );
  }
};

const BulletList = ({
  header,
  bgColor,
  bgImage,
  bgParallax,
  maxWidth = 850,
  itemConfig: {
    style = 'circle',
    textColor = '#000',
    bulletColor = '#000',
  } = {},
  items,
}: BulletListProps): ReactElement => {
  const wrapperRef = useRef<HTMLElement>(null);

  useParallaxBackground(wrapperRef.current, bgParallax);

  return (
    <Box
      component="section"
      ref={wrapperRef}
      sx={{
        pb: 8,
        ...bg({ bgColor, bgImage: getDataImage(bgImage) }),
      }}
    >
      <Header header={header} />
      <Box
        maxWidth={maxWidth === 'full' ? 'auto' : maxWidth}
        margin="0 auto"
        px={2}
      >
        {items.map((item, index) => {
          return (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              <BulletStyle
                style={style}
                color={bulletColor}
                order={index + 1}
              ></BulletStyle>
              <Typography variant="h5" color={textColor} sx={{ ml: 2 }}>
                {item}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default BulletList;
