import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@mui/material';
import { Videocam, FactCheck } from '@mui/icons-material';
import { Link } from 'gatsby';

import { Fragment } from 'react';

import { courses as types } from '@codesass/types';

type UnitTableProps = Pick<types.CourseDetails, 'units'> & {
  courseSlug: types.CourseDetails['slug'];
};

const UnitTable = ({
  courseSlug,
  units: {
    titleColor = 'text.primary',
    descColor = 'text.secondary',
    bgColor = 'text.primary',
    items,
  },
}: UnitTableProps) => {
  return (
    <Box component="section" sx={{ py: 8, backgroundColor: bgColor }}>
      <Box
        sx={{
          textAlign: 'center',
          px: 2,
          mx: 'auto',
          maxWidth: 850,
        }}
      >
        <Typography variant="h4" color={titleColor} pb={2}>
          เนื้อหาบทเรียน
        </Typography>
        <List>
          {items.map(({ title, desc, lessons }, index) => (
            <Fragment key={index}>
              <ListItem>
                <ListItemText
                  primary={`บทที่ ${index + 1}: ${title}`}
                  secondary={desc}
                  primaryTypographyProps={{
                    color: titleColor,
                    fontSize: '1.5rem',
                    mb: 2,
                  }}
                  secondaryTypographyProps={{
                    color: descColor,
                    fontSize: '1rem',
                  }}
                ></ListItemText>
              </ListItem>
              <Box sx={{ pl: 2 }}>
                <List>
                  {lessons.map(
                    (
                      { type, slug, title: lessonTitle, preview },
                      lessonIndex
                    ) => (
                      <ListItem key={lessonIndex}>
                        <ListItemIcon>
                          {type === 'video' ? (
                            <Videocam color="primary"></Videocam>
                          ) : (
                            <FactCheck color="primary"></FactCheck>
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={lessonTitle}
                          primaryTypographyProps={{ color: titleColor }}
                          secondaryTypographyProps={{ color: descColor }}
                        ></ListItemText>
                        {preview && (
                          <Button
                            variant="outlined"
                            component={Link}
                            to={`/courses/${courseSlug}/lessons/${slug}`}
                          >
                            ตัวอย่าง
                          </Button>
                        )}
                      </ListItem>
                    )
                  )}
                </List>
              </Box>
            </Fragment>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default UnitTable;
