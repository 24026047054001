import { ReactNode } from 'react';
import { graphql, PageProps } from 'gatsby';

import { courses as types } from '@codesass/types';

import SEO from 'modules/ui/components/SEO';

import CourseDetails from '../components/CourseDetails';

type CourseTemplateData = {
  coursesYaml: Omit<types.CourseDetails, 'slug' | 'desc'>;
};

type CourseTemplatePageContext = Pick<types.CourseDetails, 'slug' | 'desc'>;

const CourseTemplate = ({
  pageContext: ctx,
  data: { coursesYaml },
}: PageProps<CourseTemplateData, CourseTemplatePageContext>): ReactNode => {
  const course = { ...coursesYaml, ...ctx };

  return <CourseDetails {...course}></CourseDetails>;
};

export const query = graphql`
  query CourseQuery($slug: String!) {
    coursesYaml(fields: { slug: { eq: $slug } }) {
      title
      excerpt
      price
      promotion
      promoteVideo
      image {
        childImageSharp {
          gatsbyImageData(quality: 80, width: 1460, height: 830, layout: FIXED)
        }
      }
      smallImage: image {
        childImageSharp {
          gatsbyImageData(quality: 50, width: 175, layout: CONSTRAINED)
        }
      }
      units {
        titleColor
        descColor
        bgColor
        items {
          title
          desc
          lessons {
            type
            slug
            title
            preview
            time
          }
        }
      }
      title
      header {
        bgColor
        fgColor
        titleColor
      }
      highlight {
        bgColor
        textColor
        iconColor
      }
    }
  }
`;

export const Head = ({
  pageContext: { slug },
  data: {
    coursesYaml: { title, excerpt, image },
  },
}: PageProps<CourseTemplateData, CourseTemplatePageContext>) => {
  return (
    <SEO
      title={title}
      path={`/courses/${slug}`}
      type="article"
      coverImage={image}
      desc={excerpt}
    ></SEO>
  );
};

export default CourseTemplate;
