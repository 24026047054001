import { ReactElement, useEffect, useMemo } from 'react';

import { courses as types } from '@codesass/types';

import fbTrack from 'modules/shared/helpers/fbTrack';

import Desc from './desc/Desc';
import UnitTable from './UnitTable';
import CourseDetailsHeader from './CourseDetailsHeader';
import CourseDetailsHighlight from './CourseDetailsHighlight';

const formatMinToHour = (min: number) => {
  return `${Math.trunc(min / 60)} ชั่วโมง ` + Math.ceil(min % 60) + ' นาที';
};

const CourseDetails = ({
  slug,
  title,
  desc,
  smallImage,
  excerpt,
  price,
  promotion,
  units,
  promoteVideo,
  header,
  highlight,
}: types.CourseDetails): ReactElement => {
  const videosCount = useMemo(() => {
    let count = 0;

    for (const unit of units.items) {
      count += unit.lessons.length;
    }

    return count;
  }, [units.items]);

  const videosLength = useMemo(() => {
    let length = 0;

    for (const unit of units.items) {
      for (const lesson of unit.lessons) {
        if (lesson.type !== 'video') continue;

        length += lesson.time;
      }
    }

    return formatMinToHour(length);
  }, [units.items]);

  useEffect(() => {
    fbTrack('track', 'ViewContent', {
      content_type: 'course',
      content_ids: [slug],
      content_name: title,
      value: price,
      currency: 'THB',
    });
  }, [price, slug, title]);

  return (
    <>
      <CourseDetailsHeader
        slug={slug}
        title={title}
        excerpt={excerpt}
        smallImage={smallImage}
        price={price}
        promotion={promotion}
        promoteVideo={promoteVideo}
        header={header}
        videosCount={videosCount}
        videosLength={videosLength}
      ></CourseDetailsHeader>
      <CourseDetailsHighlight
        videosCount={videosCount}
        videosLength={videosLength}
        highlight={highlight}
      ></CourseDetailsHighlight>
      {desc && <Desc items={desc} />}
      <UnitTable courseSlug={slug} units={units}></UnitTable>
    </>
  );
};

export default CourseDetails;
