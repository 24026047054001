import {
  AccessTime,
  OndemandVideo,
  LockReset,
  WorkspacePremium,
} from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

import { courses as types } from '@codesass/types';

type CourseDetailsHighlightProps = Pick<types.CourseDetails, 'highlight'> & {
  videosCount: number;
  videosLength: string;
};

const CourseDetailsHighlight = ({
  videosCount,
  videosLength,
  highlight,
}: CourseDetailsHighlightProps) => {
  const {
    bgColor = '#fff',
    textColor = '#000',
    iconColor = '#000',
  } = highlight ?? {};

  return (
    <Box
      display="flex"
      justifyContent="center"
      gap={8}
      p={2}
      flexDirection={{ xs: 'column', md: 'row' }}
      bgcolor={bgColor}
      color={textColor}
    >
      <Stack alignItems="center">
        <AccessTime
          fontSize="large"
          sx={{ mb: 2, color: iconColor }}
        ></AccessTime>
        <Typography>วิดีโอยาวกว่า</Typography>
        <Typography fontWeight="bold">{videosLength}</Typography>
      </Stack>
      <Stack alignItems="center">
        <OndemandVideo
          fontSize="large"
          sx={{ mb: 2, color: iconColor }}
        ></OndemandVideo>
        <Typography>บทเรียนกว่า</Typography>
        <Typography fontWeight="bold">{videosCount} บทเรียน</Typography>
      </Stack>
      <Stack alignItems="center">
        <LockReset
          fontSize="large"
          sx={{ mb: 2, color: iconColor }}
        ></LockReset>
        <Typography>เข้าชมได้ทุกเมื่อ</Typography>
        <Typography fontWeight="bold">ไม่จำกัดเวลา</Typography>
      </Stack>
      <Stack alignItems="center">
        <WorkspacePremium
          fontSize="large"
          sx={{ mb: 2, color: iconColor }}
        ></WorkspacePremium>
        <Typography>มีใบ Certificate</Typography>
        <Typography fontWeight="bold">พร้อมแบบทดสอบ</Typography>
      </Stack>
    </Box>
  );
};

export default CourseDetailsHighlight;
