import {
  ReactElement,
  useRef,
  useState,
  SyntheticEvent,
  useCallback,
} from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { courses as types } from '@codesass/types';

import useParallaxBackground from 'modules/courses/hooks/useParallaxBackground';

import { getDataImage } from 'modules/courses/helpers/images';

import { bg } from './shared/styles';
import Header from './shared/Header';

type AccordionListProps = Omit<types.AccordionListDesc, 'type'>;

const AccordionList = ({
  header,
  bgColor,
  bgImage,
  bgParallax,
  maxWidth = 850,
  itemConfig: { bgColor: itemBgColor = '#fff ' } = {},
  items,
}: AccordionListProps): ReactElement => {
  const [expanded, setExpanded] = useState<string | false>(false);
  const wrapperRef = useRef<HTMLElement>(null);

  const handleChange = useCallback(
    (panel: string) => (_event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    },
    []
  );

  useParallaxBackground(wrapperRef.current, bgParallax);

  return (
    <Box
      component="section"
      ref={wrapperRef}
      sx={{
        pb: 8,
        ...bg({ bgColor, bgImage: getDataImage(bgImage) }),
      }}
    >
      <Header header={header} />
      <Box
        maxWidth={maxWidth === 'full' ? 'auto' : maxWidth}
        margin="0 auto"
        px={2}
      >
        {items.map(({ title, desc }) => {
          const slug = title.replaceAll(' ', '-');

          return (
            <Accordion
              key={slug}
              expanded={expanded === slug}
              onChange={handleChange(slug)}
              sx={{ bgcolor: itemBgColor }}
            >
              <AccordionSummary
                aria-controls={`${slug}-content`}
                id={`${slug}-header`}
              >
                <Typography>{title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{desc}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
};

export default AccordionList;
