import { ReactElement, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { courses as types } from '@codesass/types';

import useParallaxBackground from 'modules/courses/hooks/useParallaxBackground';

import { getDataImage } from 'modules/courses/helpers/images';

import { bg } from './shared/styles';
import Header from './shared/Header';

type MediaProps = Omit<types.MediaListDesc, 'type'>;

const Media = ({
  header,
  bgColor,
  bgImage,
  bgParallax,
  maxWidth = 850,
  itemConfig: {
    imageWidth,
    titleColor,
    descColor,
    itemTopMargin = 4,
    swapItemOrder = false,
    descTextAlign = 'center',
  },
  items,
}: MediaProps): ReactElement => {
  const wrapperRef = useRef<HTMLElement>(null);

  useParallaxBackground(wrapperRef.current, bgParallax);

  return (
    <Box
      component="section"
      ref={wrapperRef}
      sx={{
        pb: 8,
        ...bg({ bgColor, bgImage: getDataImage(bgImage) }),
      }}
    >
      <Header header={header} />
      <Box maxWidth={maxWidth === 'full' ? 'auto' : maxWidth} margin="0 auto">
        {items.map((item, index) => {
          return (
            <Box
              component="article"
              key={index}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              mt={itemTopMargin}
            >
              <Box
                component="img"
                width={imageWidth}
                maxWidth="100%"
                src={getDataImage(item.image.url)}
                alt={item.image.alt}
                mx="auto"
                sx={{ order: { xs: 0, md: swapItemOrder ? index % 2 : 0 } }}
                mb={{ xs: 2, md: 0 }}
                px={{ xs: 2, md: 0 }}
              ></Box>
              <Box
                textAlign="center"
                flex={1}
                mx={2}
                sx={{
                  order: { xs: 1, md: swapItemOrder ? (index + 1) % 2 : 0 },
                }}
              >
                <Typography variant="h4" mb={2} color={titleColor || grey[900]}>
                  {item.title}
                </Typography>
                <Typography
                  component="p"
                  color={descColor || grey[900]}
                  textAlign={descTextAlign}
                  sx={{ fontSize: '1.15rem' }}
                >
                  {item.desc}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Media;
