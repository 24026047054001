/* eslint-disable max-lines */
import ReactPlayer from 'react-player';
import { courses as types } from '@codesass/types';
import {
  Box,
  Container,
  Typography,
  Button,
  Paper,
  Stack,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useIntersection } from 'react-use';
import { useLocation } from '@reach/router';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { navigate } from 'gatsby';

import { courses as api, auth } from '@codesass/api';

import { useDispatch, useSelector } from 'react-redux';

import HideOnScroll from 'modules/ui/components/shared/HideOnScroll';
import { selectIsLoggedIn } from 'modules/auth/selectors';

import { getLoginPath } from 'modules/auth/helpers/paths';

import { showFlashMessage } from 'modules/ui/slice';

import fbTrack from 'modules/shared/helpers/fbTrack';

import { getCourseOverviewPath, getCourseRegisterPath } from '../helpers/paths';

const VideoPlayer = styled(ReactPlayer)({
  position: 'absolute',
  top: 0,
  left: 0,
});

type CourseDetailsHeaderProps = Pick<
  types.CourseDetails,
  | 'slug'
  | 'title'
  | 'excerpt'
  | 'smallImage'
  | 'price'
  | 'promotion'
  | 'promoteVideo'
  | 'header'
> & {
  videosCount: number;
  videosLength: string;
};

const CourseDetailsHeader = ({
  slug,
  title,
  excerpt,
  smallImage,
  promotion,
  price,
  promoteVideo,
  header,
  videosCount,
  videosLength,
}: CourseDetailsHeaderProps) => {
  // https://github.com/cookpete/react-player/issues/1474
  const [hasWindow, setHasWindow] = useState(false);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { pathname } = useLocation();
  const endOfSectionRef = useRef(null);
  const endOfSection = useIntersection(endOfSectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });
  const [buttonStatus, setButtonStatus] = useState<
    'unregistered' | 'registered' | 'subscribed'
  >('unregistered');
  const registerBtnText = useMemo(() => {
    if (!isLoggedIn) return 'สมัครเรียน';

    return buttonStatus === 'unregistered' ? 'สมัครเรียน' : 'เข้าสู่บทเรียน';
  }, [buttonStatus, isLoggedIn]);
  const courseImage = getImage(smallImage);
  const formattedPrice = `${price.toLocaleString()} บาท`;
  const formattedPromotion = `${promotion?.toLocaleString()} บาท`;

  const handleRegisterButton = useCallback(() => {
    if (!isLoggedIn) {
      navigate(getLoginPath(), { state: { prev: pathname } });
      dispatch(
        showFlashMessage({
          type: 'error',
          message: 'กรุณาเข้าสู่ระบบก่อนการลงทะเบียนเรียน',
        })
      );
      return;
    }

    if (buttonStatus === 'unregistered') {
      fbTrack('track', 'InitiateCheckout', {
        content_ids: [slug],
        content_name: title,
        value: price,
        currency: 'THB',
      });
    }

    buttonStatus === 'unregistered'
      ? navigate(getCourseRegisterPath(slug))
      : navigate(getCourseOverviewPath(slug));
  }, [buttonStatus, dispatch, isLoggedIn, pathname, price, slug, title]);

  const checkButtonStatus = useCallback(async () => {
    try {
      const registration = await api.getRegistration(slug);

      if (!registration) return;

      setButtonStatus(registration.approved ? 'subscribed' : 'registered');
    } catch (error) {
      if (error instanceof auth.LoginRequiredError) {
        setButtonStatus('unregistered');
      }
    }
  }, [slug]);

  useEffect(() => {
    checkButtonStatus();
  }, [checkButtonStatus]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true);
    }
  }, []);

  return (
    <>
      <Box
        color={header?.fgColor || '#000'}
        bgcolor={header?.bgColor || '#fff'}
        px={2}
        py={6}
      >
        <Container maxWidth="lg">
          <Box
            display="grid"
            gridTemplateAreas={{
              xs: '"title" "video" "excerpt" "registerBtn"',
              md: '"title video" "excerpt video" "registerBtn video"',
            }}
            gridTemplateRows="auto 1fr"
            gap={2}
          >
            <Typography
              variant="h4"
              component="h1"
              gridArea="title"
              color={header?.titleColor || 'inherit'}
              textAlign={{ xs: 'center', md: 'left' }}
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              component="h2"
              gridArea="excerpt"
              mt={{ xs: 2, md: 0 }}
            >
              {excerpt}
            </Typography>
            <Box minWidth={{ md: 400, lg: 500 }} gridArea="video">
              <Box
                position="relative"
                pt="56.25%"
                borderRadius={5}
                overflow="hidden"
              >
                {hasWindow && (
                  <VideoPlayer
                    url={promoteVideo}
                    playing
                    controls
                    width="100%"
                    height="100%"
                  />
                )}
              </Box>
            </Box>
            <Stack
              flexDirection={{ sm: 'column', md: 'row' }}
              alignItems="center"
            >
              {buttonStatus === 'registered' && (
                <Box
                  bgcolor={theme => theme.palette.primary.main}
                  color="#000"
                  fontSize={24}
                  p={2}
                  mr={2}
                  borderRadius={10}
                >
                  รอการตรวจสอบ
                </Box>
              )}
              {['subscribed', 'unregistered'].includes(buttonStatus) && (
                <Button
                  variant="contained"
                  component={motion.button}
                  whileHover={{ scale: 1.1 }}
                  sx={{
                    gridArea: 'registerBtn',
                    fontSize: 22,
                    borderRadius: 5,
                    px: 5,
                    mr: 2,
                  }}
                  onClick={handleRegisterButton}
                >
                  {registerBtnText}
                </Button>
              )}
              {promotion ? (
                <Box display="flex" alignItems="center" columnGap={2}>
                  <Typography component="span" sx={{ fontSize: '1.75rem' }}>
                    {formattedPromotion}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ fontSize: '2.5rem', textDecoration: 'line-through' }}
                  >
                    {`฿${price.toLocaleString()}`}
                  </Typography>
                </Box>
              ) : (
                <Typography sx={{ fontSize: '1.75rem' }}>
                  {formattedPrice}
                </Typography>
              )}
            </Stack>
          </Box>
        </Container>
      </Box>
      <div ref={endOfSectionRef}></div>
      {buttonStatus === 'unregistered' && (
        <HideOnScroll
          direction="up"
          when="down"
          visible={endOfSection && endOfSection.intersectionRatio < 1}
        >
          <Paper
            sx={{
              position: 'fixed',
              bottom: 0,
              zIndex: theme => theme.zIndex.drawer + 1,
              p: 2,
              width: '100%',
            }}
          >
            <Container maxWidth="md">
              <Stack direction="row" spacing={2} justifyContent="center">
                {courseImage && (
                  <Box display={{ xs: 'none', md: 'block' }}>
                    <GatsbyImage
                      image={courseImage}
                      title={title}
                      alt={title}
                    />
                  </Box>
                )}
                <Box>
                  <Typography variant="h6">{title}</Typography>
                  <Box display="flex" color="grey.700">
                    <Typography variant="subtitle2" pr={2}>
                      {videosCount} บทเรียน
                    </Typography>
                    <Divider
                      orientation="vertical"
                      sx={{ height: 22 }}
                    ></Divider>
                    <Typography variant="subtitle2" pl={2}>
                      {videosLength}
                    </Typography>
                  </Box>
                  <Box display="flex" mt={2}>
                    <Typography sx={{ fontSize: '1.25rem' }}>
                      {formattedPromotion}
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleRegisterButton}
                      sx={{ borderRadius: 5, ml: 2 }}
                    >
                      {registerBtnText}
                    </Button>
                  </Box>
                </Box>
              </Stack>
            </Container>
          </Paper>
        </HideOnScroll>
      )}
    </>
  );
};

export default CourseDetailsHeader;
