import { ReactElement } from 'react';

import { courses as types } from '@codesass/types';

import Text from './Text';
import MediaList from './MediaList';
import CardList from './CardList';
import TextBlock from './TextBlock';
import BulletList from './BulletList';
import AccordionList from './AccordionList';

type DescProps = {
  items: types.Desc[];
};

const Desc = ({ items }: DescProps): ReactElement => {
  return (
    <section>
      {items.map((item, index): ReactElement | null => {
        switch (item.type) {
          case 'CardList':
            return <CardList key={index} {...item} />;
          case 'MediaList':
            return <MediaList key={index} {...item} />;
          case 'Text':
            return <Text key={index} {...item} />;
          case 'TextBlock':
            return <TextBlock key={index} {...item} />;
          case 'BulletList':
            return <BulletList key={index} {...item}></BulletList>;
          case 'AccordionList':
            return <AccordionList key={index} {...item}></AccordionList>;
          default:
            return null;
        }
      })}
    </section>
  );
};

export default Desc;
